import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {Alert, AlertTitle} from '@mui/material';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import {makeStyles} from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import {userActions} from '../../_actions';
import {connect} from 'react-redux';
import {GoogleLogin} from 'react-google-login';
import {useTranslation} from 'react-i18next';
import validate from 'validate.js';
import clsx from 'clsx';
import FormField from '../../_components/Authorization/FormField';
import {SignInLogo} from '../../_components/Authorization/SignInLogo';
import {FormHeader} from '../../_components/Authorization/FormHeader';
import SignInSvg from './components/SignInSvg';

const useStyles = makeStyles(theme => ({
  alert: {
    position: 'absolute',
    maxWidth: '500px',
    bottom: '20px',
    left: '20px',
    right: '20px',
    zIndex: '30',
  },
  alert_btn: {
    marginTop: '15px',
    cursor: 'pointer',
    border: '1px solid transparent',
    boxSizing: 'border-box',
    padding: '6px 12px',
    backgroundColor: '#FFBE00',
    borderRadius: '22px',
    transition: 'all ease-in-out 0.3s',

    '&:hover': {
      backgroundColor: '#ffe000',
    },
  },
}));

const schema = {
  username: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {minimum: 4, maximum: 64},
  },
  password: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {minimum: 8, maximum: 64},
  },
  captcha: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {minimum: 8},
  },
};

const SignIn = props => {
  const {t} = useTranslation();
  const {login, loginSocial, authentication, resendEmailConfirmation} = props;
  const classes = useStyles();
  const [disabledBtn, setDisable] = useState(false);
  const theme = useTheme();
  const tab = useMediaQuery(theme.breakpoints.down(780));
  const [verificationError, setVerificationError] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      username: '',
      password: '',
      captcha: null,
    },
    touched: {},
    errors: {},
  });

  function formatPhoneNumber(value, previousValue = '') {
    const cleanValue = value.replace(/\D/g, '');

    if (previousValue && value.length < previousValue.length) {
      const formattedParts = [
        '+380',
        cleanValue.slice(3, 5),
        cleanValue.slice(5, 8),
        cleanValue.slice(8, 9),
        cleanValue.slice(9, 13),
      ];

      return formattedParts
        .filter(part => part)
        .join(' ')
        .trim();
    }

    if (!cleanValue.startsWith('380')) {
      return '+380 ' + cleanValue.replace(/^0*/, '');
    }

    if (cleanValue.length > 4) {
      const formattedNumber =
        '+380 ' +
        cleanValue.slice(3, 5) +
        ' ' +
        cleanValue.slice(5, 8) +
        ' ' +
        cleanValue.slice(8, 10) +
        ' ' +
        cleanValue.slice(10, 13);

      return formattedNumber.trim();
    }

    return '+380 ' + cleanValue.slice(3);
  }

  const myvalidate = (formData, errors) => {
    const value = formData.username;
    if (!value) return errors;

    if (!errors) errors = {};
    if (!errors.username) errors.username = [];

    if (value.startsWith('+') || value.startsWith('0')) {
      const phoneNumber = value.replace(/\D/g, '');
      // const validOperatorCodes = [
      //   '39',
      //   '50',
      //   '63',
      //   '66',
      //   '67',
      //   '68',
      //   '73',
      //   '91',
      //   '92',
      //   '93',
      //   '94',
      //   '95',
      //   '96',
      //   '97',
      //   '98',
      //   '99',
      // ];
      // const operatorCode = phoneNumber.slice(3, 5);

      if (phoneNumber.length !== 12 || !phoneNumber.startsWith('380')) {
        errors.username.push('Invalid phone number format');
      }

      // if (!validOperatorCodes.includes(operatorCode)) {
      //   errors.username.push('Invalid Ukrainian operator code');
      // }
    } else {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(value)) {
        errors.username.push('Invalid email format');
      }
    }

    if (errors.username && errors.username.length === 0) {
      delete errors.username;
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);
    const customErrors = myvalidate(formState.values, errors);

    const hasErrors = customErrors !== null;
    const isEmptyFields =
      !formState.values.username ||
      !formState.values.password ||
      !formState.values.captcha;

    setDisable(hasErrors || isEmptyFields);

    setFormState(formState => ({
      ...formState,
      isValid: !hasErrors,
      errors: customErrors || {},
    }));
  }, [formState.values]);
  
  // console.log(authentication?.error?.response);
  
  useEffect(() => {
    const error = authentication?.error?.response?.data;
    const error2 = authentication?.error?.response?.data?.detail;

    if (error?.code?.length > 0 || error2?.length > 0) {
      setVerificationError(true);
    }
  }, [authentication?.error?.response?.data?.code, authentication?.error?.response?.data?.detail]);

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  const [passwordIsShow, togglePasswordType] = useState(false);

  const handleCaptcha = value => {
    const captcha = 'captcha';
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [captcha]: value,
      },
    }));
  };

  const handleResendMail = event => {
    event.preventDefault();
    const {username} = formState.values;
    resendEmailConfirmation(username);
    setVerificationError(false);
    window.grecaptcha.reset();
  };

  const handleSignIn = event => {
    event.preventDefault();
    const {username, password, captcha} = formState.values;

    login(username, password, captcha);
    window.grecaptcha.reset();
  };

  const responseGoogle = response => {
    if (response.accessToken) {
      loginSocial(
        response.accessToken,
        'rest-auth/google/',
        response.profileObj.name,
      );
    }
  };

  const handleChange = event => {
    event.persist();
    authentication.failure = false;

    const {name, value} = event.target;
    let newValue = value;

    if (name === 'username') {
      if (value === '+380' || value === '+380 ') {
        newValue = '';
      }

      if (newValue.startsWith('+') || newValue.startsWith('0')) {
        newValue = formatPhoneNumber(newValue);

        if (newValue.replace(/\D/g, '').length > 12) {
          return;
        }
      }
    }

    setFormState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]: newValue,
      },
      touched: {
        ...prevState.touched,
        [name]: true,
      },
    }));
  };

  const newLink =
    authentication.error &&
    authentication.error.response &&
    authentication.error.response.data.non_field_errors &&
    authentication.error.response.data.non_field_errors.length > 0 &&
    authentication.error.response.data.non_field_errors[0].indexOf('E-mail') ===
      0;
  // const newLink = false;

  return (
    <div className="user-main__wrapper">
      <div className="user-main__inner">
        <SignInLogo />
        <div className="user-main__right">
          <div className="user-main__right-form user-form user-form-login">
            <FormHeader />
            <div className="mb-0 mt-0">
              <form onSubmit={handleSignIn}>
                <FormField
                  name="username"
                  handleChange={handleChange}
                  type="text"
                  title={t('common:enter_email_or_phone')}
                  formState={formState}
                  hasErrorText={
                    formState.values.username &&
                    (formState.values.username.startsWith('+') ||
                      formState.values.username.startsWith('0'))
                      ? t('common:enter_phone3')
                      : t('common:enter_email2')
                  }
                />
                <FormField
                  name="password"
                  handleChange={handleChange}
                  type="password"
                  title={t('common:enter_password')}
                  formState={formState}
                />
                {/*{authentication?.error?.response?.data?.detail[0] === 'User not found.' && (*/}
                {/*  <p>*/}
                {/*    {authentication?.error?.response?.data?.detail[0]}*/}
                {/*  </p>)}*/}
                <Link to="/forgot-pw" className="user-form__link mt-7 mb-4">
                  {t('common:Forgot your password?')}
                </Link>
                <div className="user-form__captcha mb-4">
                  <ReCAPTCHA
                    sitekey={'6LcqltkUAAAAAJ5dGwJgNtTUz91abygZNj8TtIGF'}
                    onChange={handleCaptcha}
                    style={{maxWidth: 200}}
                  />
                </div>
                <button
                  className={`${
                    disabledBtn ? 'btn-disabled' : 'btn'
                  } btn-big btn--w100 mb-6`}>
                  {t('common:come_in')}
                </button>
                <div className="user-form__footer">
                  <GoogleLogin
                    clientId="351598824340-3noo4sh4b7r0afdh4me431ikq7pped1b.apps.googleusercontent.com" //c7vDfL9L7ox1c3pJiDroRVCd
                    buttonText=""
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    // onLogoutSuccess={this.logout}
                    render={renderProps => (
                      <button
                        onClick={renderProps.onClick}
                        className="user-form__footer-button">
                        {t('common:Registration through')}
                        <span className="user-form__footer-google">
                          <SignInSvg />
                        </span>
                      </button>
                    )}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {verificationError && (
        <Alert
          severity="error"
          className={clsx(classes.alert)}
          onClose={() => setVerificationError(false)}>
          <AlertTitle>
            <strong>{t('common:error')}</strong>
          </AlertTitle>
          {t('common:E-mail is not confirmed')}.
          <br />
          <button
            onClick={handleResendMail}
            className={clsx(classes.alert_btn)}>
            {t('common:SEND')}
          </button>
        </Alert>
      )}
    </div>
  );
};

function mapState(state) {
  const {authentication} = state;
  const {loggedIn} = state.authentication;
  return {loggedIn, authentication};
}

const actionCreators = {
  login: userActions.login,
  resendEmailConfirmation: userActions.resendEmailConfirmation,
  logout: userActions.logout,
  loginSocial: userActions.loginSocial,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.authentication.error = null;
  }

  render() {
    return <SignIn {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
