import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Hidden, IconButton } from '@material-ui/core';

import Carousel from './components/Carousel/Carousel';
import Details from './components/Details';

import Typography from '@material-ui/core/Typography';

import { cartActions, searchActions } from '../../_actions';
// import {promotionActions} from "../../_actions/promotion.actions";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './ProductPage.scss';
import SliderPhoto from '../../_components/SliderPhoto';
import { getCurrencyContrData } from '../../_helpers';
import AddToCartDialog from '../ProductList/components/AddToCartDialog/AddToCartDialog';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ProductPageTabs from './components/ProductPageTabs';
import ProductPageExpansionPanel from './components/ProductPageExpansionPanel';
import ProductPageAnalogListBob from './components/ProductPageAnalogListBob';
import AboutGoods from '../../_components/AboutGoods';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import AnalogInStock from './components/AnalogInStock';
import AddToFavorite from '../ProductList/components/AddToFavorite/AddToFavorite';
import DiscountLabel from '../../_components/DiscountLabel';
import Button from '@material-ui/core/Button';
// import {randomPromoProduct} from "../../_reducers/search/randomPromoProduct";
import SliderRandomProduct from '../../HomePage/components/SliderRandomProduct';
import SliderPromo from '../../HomePage/components/SliderPromo';
import { DetailProductSlider } from './components/DetailProductSlider';
import DetailProductInfo from './components/DetailProductInfo';
import { ProductInlineCarousel } from './components/ProductInlineCarousel/ProductInlineCarousel';
import BreadcrumbsForSearch from '../../_components/BreadcrumbsForSearch';
import { BreadCrumbs } from '../../_components/BreadCrumbs/BreadCrumbs';
import DetailProductTabs from './components/DetailProductTabs';
import { CashbackBanners } from './components/CashbackBanners';
import { ProductList } from '../../_components/Product/ProductList';
import { ProductHeader } from '../../_components/Product/ProductHeader';
// import {BannerCarousel} from './components/BannerCarousel';
import BannerCarousel from './components/BannerCarousel';
import Preloader from '../../_components/Preloader';
import { Helmet } from 'react-helmet';
import { DetailProductSkeleton } from './components/DetailProductSkeleton';
import axInst from '../../_helpers/axios-instance';
import { Link } from 'react-router-dom';

// import {promotionItems} from "../../_reducers/promotion.reducer";
// import BasketAdd from "../../icons/BasketAdd";

const useStyles = makeStyles(theme => ({
  root: {},

  container_back_button: {
    marginTop: 0,
    marginBottom: 0,

    '&>.MuiGrid-item': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '&.MuiGrid-item': {
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },

    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fff',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  header: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(1),
    marginBottom: 0,
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fff',
    },
  },
  product_code: {
    textAlign: 'right',
  },
  navButton: {
    marginLeft: -3,
    padding: '12px 0',

    [theme.breakpoints.up('md')]: {
      padding: '4px 0',
    },

    '& span': {
      fontSize: 14,
      color: '#606060',
      '& span': {
        marginLeft: 5,
      },
    },
  },

  title: {
    color: '#000',
  },

  wrapper: {
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fff',
      // paddingLeft: 8,
      // paddingRight: 8,
    },
    [theme.breakpoints.down('md')]: {
      // paddingLeft: 15,
      // paddingRight: 15,
    },
    [theme.breakpoints.up('md')]: {
      // paddingBottom: 32,
    },
  },

  accordion_container_for_mobile: {
    '&>.MuiGrid-item>.MuiPaper-root>.MuiExpansionPanelSummary-root .MuiExpansionPanelSummary-content': {
      margin: '19.6px 0',
    },

    // "&>.MuiGrid-item>.MuiPaper-root>.MuiExpansionPanelSummary-root .MuiSvgIcon-root": {
    //     width: "1.6em",
    //     height: " 1.6em",
    //     color: "#000",
    // },
    '&>.MuiGrid-item>.MuiPaper-root>.MuiCollapse-container>.MuiCollapse-wrapper>.MuiCollapse-wrapperInner>*>.MuiExpansionPanelDetails-root': {
      paddingRight: 0,
      paddingLeft: 0,
    },

    '&>.MuiGrid-item>.MuiPaper-root': {
      boxShadow: 'none',
    },

    '&>.MuiGrid-item>.MuiPaper-root>.MuiExpansionPanelSummary-root': {
      padding: 0,
    },

    '&>.MuiGrid-item>.MuiExpansionPanel-root:before': {
      backgroundColor: '#aaa',
    },

    // "&>.MuiGrid-item>.MuiExpansionPanel-root.Mui-expanded:after": {
    //     position: "absolute",
    //     top: 0,
    //     height: 1,
    //     width: "100%",
    //     left: 0,
    //     right: 0,
    //     backgroundColor: "#aaa",
    //     content: "''",
    // },
  },

  promoBlock: {
    display: 'flex',
    width: '100%',
  },

  promoBlockLabel: {
    background: '#f1e63b',
    position: 'relative',
    width: '40px',
    height: '85px',
    borderRadius: '2px',

    '& div': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) rotate(-90deg)',
      width: '85px',
      fontSize: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },

  promoBlockInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 0 0 8px',
    padding: '0 15px',
    background: '#fff',
    width: '100%',
  },

  randomProduct: {
    // maxWidth: '20%',
    width: '310px',
    height: '460px',
    // height: '100%',
    // padding: '0 20px',
    margin: '71px 0 0 0',
    overflow: 'hidden',
    position: 'sticky',
    top: 80,
    flexShrink: '0',

    '& .slick-dots': {
      bottom: '-4px',
    },
  },

  promoListVariant3: {
    // maxHeight: '455px',
    // height: '100%',

    '& .slick-list': {
      height: '435px',
    },
    '& .slick-slider': {
      height: '100%',
    },
  },

  // promoName: {}
}));

const Product = props => {
  const {
    productCart,
    history,
    main_currency,
    cart,
    addToCart,
    currencies,
    sell_currency_id,
    //customer_id
    // searchByArticleOwn,
    // searchByArticleOffer,
    searchByCrossOwn,
    searchByCrossOffer,
    searchStatus,
    customer_data,
    listSearchRelated,
    listMyFavorite,
    authentication,
    searchPromotions,
    promotionsByProductsList,
    productMetaData,
    // randomPromoProduct,
  } = props;

  const { result } = productCart;
  const customer_group_id = customer_data && customer_data.discount_group_id;

  const [photosGallery, setPhotosGallery] = React.useState();
  const [promotions, setPromotions] = React.useState();
  // const setTabIndex = React.useState(0);
  const [analogList, setAnalogList] = React.useState(true);
  const [relatedList, setRelatedList] = React.useState(true);
  const [selectUuid, setSelectUuid] = React.useState(false);
  const [anchorElInfo, setAnchorElInfo] = useState(null);
  const [promoProductsCount, setPromoProductsCount] = useState(0);
  const [promoDataLoading, setPromoDataLoading] = useState(true);
  // const [reviewIsActive, setReviewIsActive] = useState(false);
  const reviewIsActive = useRef(null);
  const tabsRef = useRef();
  // const [randomPromoProductList, setRandomPromoProductList] = useState([]);

  React.useEffect(() => {
    if (
      listSearchRelated.loading !== undefined &&
      listSearchRelated.loading === false &&
      searchByCrossOwn.loading !== undefined &&
      searchByCrossOwn.loading === false &&
      result &&
      result.article !== undefined
    ) {
      let product_id = [];
      if (listSearchRelated.results)
        product_id = listSearchRelated.results.map(it => it.product_id);
      let product_id2 = [];
      if (searchByCrossOwn.results)
        product_id2 = searchByCrossOwn.results.map(it => it.product_id);
      product_id = product_id.concat(product_id2);
      if (result.article.product_id) product_id.push(result.article.product_id);

      if (product_id?.length > 0) searchPromotions(product_id);
    }

    // setRandomPromoProductList(randomPromoProduct)
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listSearchRelated, searchByCrossOwn, result]);

  useEffect(() => {
    if (result) {
      axInst
        .post('/api-promotion/get-promotion-product/', JSON.stringify({
          product_id: result?.article?.product_id,
        }))
        .then(res => setPromotions(res.data));
    }
  }, [result]);

  const settingsRandomProductSlider = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesPerRow: 1,
    rows: 2,
    autoplay: false,
    variant: 'variant2',
    customStyle: 'customStyle1',
  };

  const settingsPromotionSlider = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesPerRow: 1,
    rows: 1,
    autoplay: true,
    variant: 'variant3',
    autoplaySpeed: 6000,
  };

  const handleUuidInfo = uuid => event => {
    event.stopPropagation();
    setSelectUuid(uuid);
    setAnchorElInfo(event.currentTarget);
  };
  const handleCloseUuidInfo = () => {
    setAnchorElInfo(null);
  };

  /*const showProductCart = (brand, article) => event => {
        history.push(`${'/product/'}${brand}${'/'}${article}`);
    };*/

  const classes = useStyles();

  const currencyMain =
    sell_currency_id &&
    currencies &&
    currencies.find(x => x.id === sell_currency_id);
  //const currencyMainSite = getCurrencySiteData(currencyMain, main_currency, sell_currency_id);
  const currencyMainContr = getCurrencyContrData(
    currencyMain,
    main_currency,
    sell_currency_id,
    customer_data,
  );

  let cartAll = cart && cart.list ? cart.list.all : [];
  const handleBack = () => {
    history.goBack();
  };

  let resultFavorite =
    listMyFavorite.dataMyFavorite && listMyFavorite.dataMyFavorite.result
      ? listMyFavorite.dataMyFavorite.result
      : null;

  const { t } = useTranslation();

  /*if (productCart.loading === undefined ||
        productCart.loading === true) return <React.Fragment>  </React.Fragment>;*/
  if (!result) return <DetailProductSkeleton />;

  if (searchStatus.loading === false) {
    if (searchByCrossOffer.results && searchByCrossOffer.results?.length > 0) {
      if (searchByCrossOwn.results && searchByCrossOwn.results?.length > 0) {
        searchByCrossOwn.results = searchByCrossOwn.results.concat(
          searchByCrossOffer.results,
        );
      } else searchByCrossOwn.results = searchByCrossOffer.results;
      searchByCrossOffer.results = [];
    }
  }

  if (result.on_stock) {
    let abbr = currencyMain ? currencyMain.abbr : 'UAH';
    result.countInCart = 0;
    result.countInStoks = 0;
    result.isRequest = 0;
    if (
      result.on_stock?.length === 0 &&
      result.on_request &&
      result.on_request?.length > 0
    ) {
      result.isRequest = 1;
      result.on_request.map(val => {
        val.items.map(item => {
          item.priceCurrent = currencyMainContr.koef * item.price;
          result.on_stock.push(item);
          return true;
        });
        return true;
      });
    }

    result.on_stock.sort((a, b) => a.price - b.price); //По возрастанию
    result.isDiscount = 0;
    let tmp;
    if (resultFavorite !== null) {
      tmp = resultFavorite.find(x => x.sku === result.article.sku);
      if (tmp) result.favorite_id = tmp.id;
    }
    result.on_stock.map((el, index) => {
      result.countInStoks += parseInt(el.quantity.replace(/[^\d]/gi, ''));
      el.delivery = el.warehouse_id;
      el.name = el.warehouse_name;
      el.priceCurrent = el.res_price_all_valutes[abbr].price_discount;
      el.regularPrice = el.res_price_all_valutes[abbr].regular_price;
      if (index === 0) {
        if (el.priceCurrent !== el.regularPrice) {
          result.isDiscount = Math.round(
            ((el.regularPrice - el.priceCurrent) / el.regularPrice) * 100,
          );
        }
      }

      cartAll.map(val => {
        if (el.uuid === val.price_uuid) {
          el.countInCart = val.quantity;
          result.countInCart += val.quantity;
        }
        return true;
      });
      return true;
    });
  }

  return (
    <>
      <BreadCrumbs
        items={result?.bread_crumb?.map(i => ({
          path: i.bread_crumb_url,
          title: i.bread_crumb_name,
          type: i.bread_crumb_url_type,
        }))}
      />
      {/* <>
        <Link  to={result?.bread_crumb[result?.bread_crumb.length-2].bread_crumb_url}>
          {result?.bread_crumb[result?.bread_crumb.length-2].bread_crumb_name}
        </Link>
        
      </> */}
      <main>
        <div className="wrapper-inner mb-3">
          <div className="row">
            <DetailProductSlider result={result} />
            <DetailProductInfo
              promotions={promotions}
              promoProductsCount={promoProductsCount}
              promoDataLoading={promoDataLoading}
              currencyMain={currencyMain}
              result={result}
              reviewIsActive={reviewIsActive}
              tabsRef={tabsRef}
            />
            <ProductInlineCarousel
              setPromoProductsCount={setPromoProductsCount}
              setPromoDataLoading={setPromoDataLoading}
            />
          </div>
        </div>
        <div className="wrapper-inner">
          <div className="row">
            <div className="col-xxl-10 col-12">
              <DetailProductTabs
                currencyMainContr={currencyMainContr}
                cartAll={cartAll}
                result={result}
                currencyMain={currencyMain}
                reviewIsActive={reviewIsActive}
                tabsRef={tabsRef}
              />
            </div>
            <div className="col-xxl-2 d-xxl-block d-none">
              <BannerCarousel />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

function mapState(state) {
  const { currencies, main_currency } = state.frontendData;
  const { customer_id, sell_currency_id } = state.userSelectedData;
  const { customer_data } = state.contragentData;
  const {
    productCart,
    cart,
    // searchByArticleOwn,
    // searchByArticleOffer,
    searchByCrossOwn,
    searchByCrossOffer,
    searchStatus,
    listSearchRelated,
    listMyFavorite,
    authentication,
    promotionsByProductsList,
    productMetaData,
    // randomPromoProduct,
    // promotionItems
  } = state;

  return {
    productCart,
    cart,
    currencies,
    main_currency,
    customer_id,
    sell_currency_id,
    // searchByArticleOwn,
    // searchByArticleOffer,
    searchByCrossOwn,
    searchByCrossOffer,
    searchStatus,
    customer_data,
    listSearchRelated,
    listMyFavorite,
    authentication,
    promotionsByProductsList,
    productMetaData,
    // randomPromoProduct,
    // promotionItems
  };
}

const actionCreators = {
  getProductCart: cartActions.getProductCart,
  addToCart: cartActions.addToCart,
  getCart: cartActions.getCart,
  getMetaData: cartActions.getMetaData,
  setSearchDataAndSearch: searchActions.setSearchDataAndSearch,
  searchRelated: searchActions.searchRelated,
  getMyFavorite: searchActions.getMyFavorite,
  searchPromotions: searchActions.searchPromotions,
  // searchRandomPromoProduct: promotionActions.searchRandomPromoProduct,
  // promotionListItems: promotionActions.promotionListItems,
};

class Wrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      numbersResult: null,
    };
  }

  // getOriginalNumbers = () => {
  //   axInst.post('/api/get-original-numbers/', JSON.stringify({
  //     product_sku: this.props.productCart?.result?.article?.sku
  //   }))
  //     .then(res => {
  //       this.setState({ numbersResult: res.data });
  //     });
  // };

  componentDidMount() {
    this.props.getMetaData(this.props.match.params.article);
    this.props.getMyFavorite();
    // this.getOriginalNumbers();
    this.props.getProductCart(
      this.props.match.params.brand,
      this.props.match.params.article,
    );
    if (!this.props.cart.list) this.props.getCart();

    const searchData = {
      brand: this.props.match.params.brand,
      article: this.props.match.params.article,
    };
    let onlyAnalog = true;
    this.props.setSearchDataAndSearch(searchData, onlyAnalog);

    this.props.searchRelated();
    // this.props.searchRandomPromoProduct();
    // this.props.promotionListItems();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.sell_currency_id !== prevProps.sell_currency_id ||
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.props.getProductCart(
        this.props.match.params.brand,
        this.props.match.params.article,
      );
      const searchData = {
        brand: this.props.match.params.brand,
        article: this.props.match.params.article,
      };
      let onlyAnalog = true;
      this.props.setSearchDataAndSearch(searchData, onlyAnalog);

      this.props.searchRelated();
    }

    // if (this.props.productCart.result !== prevProps.productCart.result) {
    //   this.getOriginalNumbers();
    // }
  }

  render() {
    const originalNumbersArr = this.state.numbersResult?.original_numbers;
    const originalNumbersStr = '| ' + originalNumbersArr?.join(', ') || '';

    return (
      <>
        <Helmet>
          <meta
            name="description"
            content={this.props.productMetaData?.meta?.description}
          />
          <meta
            name="keywords"
            content={this.props.productMetaData?.meta?.keywords}
          />
          <title>{this.props.productMetaData?.title}</title>
          {/* <title>{`${this.props.productMetaData?.title} ${originalNumbersStr}`}</title> */}
          {/* <title>PERFECTO</title> */}

          <link rel="canonical" href="https://daniparts.com" />
        </Helmet>
        <Product {...this.props} />
      </>
      // <Product {...this.props} />
    );
  }
}

Wrapper.propTypes = {
  history: PropTypes.object,
};

export default connect(mapState, actionCreators)(withRouter(Wrapper));
