import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Skeleton} from '@material-ui/lab';
import {Typography, useMediaQuery, useTheme} from '@material-ui/core';
import {useState} from 'react';
import {useEffect} from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  banner_skeleton: {
    width: 170,
    height: 170,
    background: 'red',
  },
  skeleton_inner: {
    width: '100%',
    height: 170,
    paddingLeft: 20,
    backgroundColor: '#DBDDE0',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  },
}));
export const BannerSkeleton = () => {
  const classes = useStyles();
  const theme = useTheme();
  const bannerIconBreakpoint = useMediaQuery(theme.breakpoints.down);
  const [bannersCount, setBannersCount] = useState(4);

  return (
    <div className="banners">
      <div className="wrapper-inner">
        <div className="all-banners" style={{gap: 20}}>
          {new Array(bannersCount).fill(bannersCount).map((_, index) => (
            <div
              key={index}
              className={clsx(
                classes.skeleton_inner,
                'banner-skelton-wrapper',
              )}>
              <Skeleton
                variant="circle"
                width={bannerIconBreakpoint ? 90 : 72}
                height={bannerIconBreakpoint ? 90 : 72}
              />
              <div className="skeleton-title">
                <Typography variant={bannerIconBreakpoint ? 'h1' : 'h2'}>
                  <Skeleton width={220} />
                </Typography>
                <Typography variant={bannerIconBreakpoint ? 'h5' : 'h6'}>
                  <Skeleton width={180} />
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
