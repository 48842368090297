import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {Grid, Button, TextField, Typography, Select} from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import PersonIcon from '@material-ui/icons/Person';
import CompanyIcon from '@material-ui/icons/Work';

import {userActions} from '../../_actions';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import InputElement from 'react-input-mask';
import clsx from 'clsx';
import BottomInfo from '../../layouts/Minimal/components/BottomInfo';
import LoginPageContainer from '../../_components/LoginPageContainer';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import axInst from '../../_helpers/axios-instance';

const useStyles = makeStyles(theme => ({
  root: {
    // height: '100%',
  },
  root2: {
    // height: 'calc(100vh - 666px)',
  },
  grid: {
    // height: '100%',
  },
  content: {
    // height: '100%',
    // display: 'flex',
    // flexDirection: 'column',
  },
  icons: {
    display: 'block',
    fontSize: 30,
    color: '#616161',
    marginLeft: -4,
  },
  contentBody: {
    // marginTop: 20,
    // flexGrow: 1,
    // display: 'flex',
    // alignItems: 'center',
    // [theme.breakpoints.down('md')]: {
    //     justifyContent: 'center',
    // },
  },
  form: {
    // maxWidth: 368,
    // width: '100%',
    // margin: '0 auto',
    // background: '#fff',
    // padding: 10,
    // [theme.breakpoints.down('md')]: {
    //     flexBasis: 600,
    //     paddingLeft: theme.spacing(2),
    //     paddingRight: theme.spacing(2),
    // },
    // [theme.breakpoints.down('sm')]: {
    //     flexBasis: '100%',
    //     paddingLeft: theme.spacing(2),
    //     paddingRight: theme.spacing(2),
    // },
  },
  // footer: {
  //     display: 'flex',
  //     flexFlow: 'column wrap',
  //     alignItems: 'center',
  //     marginTop: '4.5em',
  //     fontSize: 12,
  //     color: '#444',
  //     '& a': {
  //         color: '#1464f6',
  //         textDecoration: 'none',
  //         '&:hover': {
  //             textDecoration: 'underline',
  //         },
  //     },
  // },
  // title: {
  //     marginTop: theme.spacing(3),
  //     marginBottom: theme.spacing(3),
  //     fontWeight: 400,
  //     fontSize: 26,
  //     color: '#000',
  //     textAlign: 'center',
  // },
  smallFont: {
    fontSize: 14,
    color: 'black',
  },
  textField: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    height: 36,
  },
  singleIcon: {
    paddingLeft: 4,
    '& .MuiFormHelperText-root': {
      color: '#d32f2f',
    },
  },
  button: {
    margin: '1em 0',
  },
  registered: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    // alignItems: 'center',
    fontSize: 14,
    '& p': {
      margin: 0,
    },
    '& a': {
      // position: 'absolute',
      // left: '50%',
      // transform: 'translateX(-50%)',
      textDecoration: 'none',
      color: '#7a7a7a',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },

  inputStyle: {
    padding: '1px',
    marginBottom: '10px',

    '& input': {
      borderRadius: '50px',
      padding: '10px',
      margin: '0',
    },
  },

  radio: {
    '& span': {
      '& div': {
        '& svg': {
          fill: '#FFBE00 !important',
        },
      },
    },
  },
}));

/*
const ColorCheckbox = withStyles({
  root: {
    color: '#616161',
    '&$checked': {
      color: '#006ce5',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);
*/

const schema = {
  name: {
    presence: true, // {allowEmpty: false, message: 'is required'},
    length: {minimum: 2, maximum: 64},
  },
  last_name: {
    presence: true,
    length: {minimum: 2, maximum: 64},
  },
};

const ConfirmEmail = props => {
  const {t} = useTranslation();
  const {authentication, createCustomer} = props;
  const [mobileOperatorCodes, setMobileOperatorCodes] = useState([]);
  // const [selectOperatorCodes, setSelectOperatorCodes] = useState('');

  const classes = useStyles();
  const [formState, setFormState] = useState({
    isValid: false,
    isSend: false,
    values: {
      name: authentication.gfd_user ? authentication.gfd_user.first_name : '',
      last_name: authentication.gfd_user
        ? authentication.gfd_user.last_name
        : '',
      // phone: '',
      operatorCode: '',
      contractor_type: null, //'PP',
      official_name: '',
      already_work: null,
      locality: '',
      branch: '',
    },
    touched: {},
    errors: {},
  });

  function myvalidate(formData, errors) {
    if (
      formData.contractor_type === 'CO' &&
      formData.official_name.length < 2
    ) {
      if (!errors) errors = {};
      if (!errors.official_name) errors.official_name = [];
      errors.official_name.push('===contractor_type');
    }
    if (
      (formData.contractor_type === 'CO' ||
        formData.contractor_type === 'PP') &&
      formData.already_work === 'y' &&
      formData.locality.length < 1
    ) {
      if (!errors) errors = {};
      if (!errors.locality) errors.locality = [];
      errors.locality.push('===contractor_type');
    }
    if (
      formData.contractor_type === 'CO' &&
      formData.already_work === 'y' &&
      formData.branch.length < 1
    ) {
      if (!errors) errors = {};
      if (!errors.branch) errors.branch = [];
      errors.branch.push('===contractor_type');
    }
    // if (formData.phone.replace(/[^\d]/ig, '').length < 7) {
    //   if (!errors) errors = {};
    //   if (!errors.phone) errors.phone = [];
    //   errors.phone.push("===phone_error");
    // }

    // if (formState.values.operatorCode.length < 5) {
    //   if (!errors) errors = {};
    //   if (!errors.operatorCode) errors.operatorCode = [];
    //   errors.operatorCode.push("Operator Code error");
    // }

    if (
      !(formData.contractor_type === 'CO' || formData.contractor_type === 'PP')
    ) {
      if (!errors) errors = {};
      if (!errors.contractor_type) errors.contractor_type = [];
      errors.contractor_type.push('!CO || PP');
    }
    if (!(formData.already_work === 'y' || formData.already_work === 'n')) {
      if (!errors) errors = {};
      if (!errors.already_work) errors.already_work = [];
      errors.already_work.push('null');
    }

    return errors;
  }

  useEffect(() => {
    axInst.post('/shopping-cart/get-mobile-operator-codes/', {}).then(res => {
      setMobileOperatorCodes(res.data.result);
    });
  }, []);

  // const getSelectOperatorCodes= event => {
  //   setSelectOperatorCodes(event.target.value);
  //
  // };

  useEffect(() => {
    let errors = validate(formState.values, schema);
    errors = myvalidate(formState.values, errors);
    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));

    // if (selectOperatorCodes.length < 5) {
    //   if (!errors) errors = {};
    //   if (!errors.selectOperatorCodes) errors.selectOperatorCodes = [];
    //   errors.phone.push("Operator Code error");
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  const handleSignIn = event => {
    event.preventDefault();
    setFormState(formState => ({...formState, isSend: true}));
    createCustomer(formState.values);
  };
  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  // if (!authentication.loggedIn && authentication.error) return <React.Fragment style={{textAlign: 'center'}}>БОЛТ</React.Fragment>;
  if (authentication.error)
    return (
      <React.Fragment>
        {authentication.error.response &&
          authentication.error.response.data &&
          authentication.error.response.data.detail}
      </React.Fragment>
    );

  return (
    <LoginPageContainer className={'confirm_email_container'}>
      <form className={clsx(classes.form, 'form')} onSubmit={handleSignIn}>
        <Grid item xs={12} lg={12}>
          <Typography className={clsx(classes.title, 'title')} variant="h2">
            {t('common:Registration completion')}
          </Typography>
        </Grid>

        <Grid item xs={12} lg={12}>
          <Typography color="#414D5F" className={classes.smallFont}>
            {t('common:You have successfully registered')}
          </Typography>
        </Grid>

        <Grid item xs={12} lg={12}>
          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              aria-label="LabelRadio"
              name="contractor_type"
              value={formState.values.contractor_type}
              onChange={handleChange}>
              <Grid container>
                <Grid item xs={6} lg={6}>
                  <FormControlLabel
                    value="PP"
                    control={<Radio className={clsx(classes.radio)} />}
                    label={
                      <Typography className={classes.smallFont}>
                        {t('common:Private person')}
                      </Typography>
                    }
                    // labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <FormControlLabel
                    value="CO"
                    control={<Radio className={clsx(classes.radio)} />}
                    label={
                      <Typography className={classes.smallFont}>
                        {t('common:Legal entity')}
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <hr />
        </Grid>

        <Grid item xs={12} lg={12}>
          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              aria-label="LabelRadio"
              name="already_work"
              value={formState.values.already_work}
              onChange={handleChange}>
              <Grid container spacing={0}>
                <Grid item xs={12} lg={12}>
                  <FormControlLabel
                    value="y"
                    control={
                      <Radio className={clsx(classes.radio)} color="primary" />
                    }
                    label={
                      <Typography className={classes.smallFont}>
                        {t('common:I already work with your company')}
                      </Typography>
                    }
                    // labelPlacement="start"
                  />
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={1} lg={1} />
                    <Grid item xs={11} lg={11}>
                      {formState.values.already_work === 'y' && (
                        <Grid item>
                          <Typography
                            style={{marginBottom: '20px', opacity: '0.8'}}
                            className={classes.smallFont}>
                            {t('common:We will be in contact with you')}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={0}>
                <Grid item xs={12} lg={12}>
                  <FormControlLabel
                    value="n"
                    control={
                      <Radio className={clsx(classes.radio)} color="primary" />
                    }
                    label={
                      <Typography className={classes.smallFont}>
                        {t('common:I am a new customer')}
                      </Typography>
                    }
                    // labelPlacement="start"
                  />
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={1} lg={1} />
                    <Grid item xs={11} lg={11}>
                      {formState.values.already_work === 'n' && (
                        <Grid item>
                          <Typography
                            style={{marginBottom: '20px', opacity: '0.8'}}
                            className={classes.smallFont}>
                            {t('common:We register Your Company')}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>

          {formState.values.contractor_type === 'CO' && (
            <div>
              <Grid container className={classes.textField1}>
                <Grid item xs={1}>
                  <CompanyIcon className={classes.icons} />
                </Grid>
                <Grid item xs={11} className={classes.singleIcon}>
                  <TextField
                    error={hasError('official_name')}
                    className={classes.inputStyle}
                    fullWidth
                    placeholder={t('common:Enter the name of the company')}
                    helperText={
                      hasError('official_name')
                        ? t('common:It is essential to the company name')
                        : null
                    }
                    name="official_name"
                    onChange={handleChange}
                    value={formState.values.official_name || ''}
                  />
                </Grid>
              </Grid>
            </div>
          )}

          {formState.values.already_work === 'y' && (
            <div>
              <Grid item xs={12} lg={12}>
                <Grid container>
                  <Grid item xs={1} lg={1} />
                  <Grid item xs={11} lg={11} className={classes.singleIcon}>
                    <TextField
                      fullWidth
                      className={classes.inputStyle}
                      placeholder={t('common:Locality')}
                      name="locality"
                      onChange={handleChange}
                      value={formState.values.locality}
                      error={hasError('locality')}
                      helperText={
                        hasError('locality')
                          ? t('common:locality (required)')
                          : null
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              {formState.values.contractor_type === 'CO' && (
                <Grid item xs={12} lg={12}>
                  <Grid container>
                    <Grid item xs={1} lg={1} />
                    <Grid item xs={11} lg={11} className={classes.singleIcon}>
                      <TextField
                        fullWidth
                        className={classes.inputStyle}
                        placeholder={t('common:Branch')}
                        name="branch"
                        onChange={handleChange}
                        value={formState.values.branch}
                        error={hasError('branch')}
                        helperText={
                          hasError('branch')
                            ? t('common:branch (required)')
                            : null
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          )}
        </Grid>

        <Grid container spacing={0} style={{width: '96%'}}>
          <Grid item xs={12} lg={12} style={{marginTop: 8}}>
            <Grid container>
              <Grid item xs={1}>
                <PersonIcon className={classes.icons} />
              </Grid>
              <Grid item xs={11} className={classes.singleIcon}>
                <TextField
                  className={classes.inputStyle}
                  variant="outlined"
                  error={hasError('name')}
                  fullWidth
                  placeholder={t('common:Name (required)')}
                  helperText={
                    hasError('name') ? t('common:Name (required)') : null
                  }
                  name="name"
                  onChange={handleChange}
                  value={formState.values.name || ''}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Grid container>
              <Grid item xs={1} lg={1} />
              <Grid item xs={11} lg={11} className={classes.singleIcon}>
                <TextField
                  className={classes.inputStyle}
                  variant="outlined"
                  error={hasError('last_name')}
                  fullWidth
                  placeholder={t('common:Surname')}
                  helperText={
                    hasError('last_name')
                      ? t('common:Surname (required)')
                      : null
                  }
                  name="last_name"
                  onChange={handleChange}
                  value={formState.values.last_name || ''}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/*<Grid container className={classes.textField}>*/}
        {/*  <Grid item xs={1}>*/}
        {/*    <PhoneIcon className={classes.icons}/>*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={11} spacing={2} container className={classes.singleIcon}>*/}
        {/*    <Grid className={"input_grid"} item xs={6} lg={6}>*/}
        {/*      <FormControl*/}
        {/**/}
        {/*          className={"order_select_container"}*/}
        {/*          variant="outlined"*/}
        {/*          size="small"*/}
        {/*          style={{width: '100%'}}*/}
        {/*      >*/}
        {/*        <InputLabel id="operator_type">{t('common:operator_code')}</InputLabel>*/}
        {/*        <Select*/}
        {/**/}
        {/*            value={formState.values.operatorCode}*/}
        {/*            name="operatorCode"*/}
        {/*            onChange={handleChange}*/}
        {/*            labelWidth={50}*/}
        {/*            labelId="operator_type"*/}
        {/*            label={t('common:operator_code')}*/}
        {/*            error={hasError('operatorCode')}*/}
        {/*            id="operator_type"*/}
        {/**/}
        {/*            MenuProps={{*/}
        {/*              anchorOrigin: {*/}
        {/*                vertical: "bottom",*/}
        {/*                horizontal: "left"*/}
        {/*              },*/}
        {/*              transformOrigin: {*/}
        {/*                vertical: "top",*/}
        {/*                horizontal: "left"*/}
        {/*              },*/}
        {/*              getContentAnchorEl: null*/}
        {/*            }}*/}
        {/**/}
        {/*        >*/}
        {/*          {mobileOperatorCodes && mobileOperatorCodes.map((item, index) =>*/}
        {/*              <MenuItem key={index} value={`${item.country_code} (${item.operator_name})`}>{item.country_code} ({item.operator_name})</MenuItem>*/}
        {/*          )}*/}
        {/**/}
        {/**/}
        {/*        </Select>*/}
        {/*      </FormControl>*/}
        {/*    </Grid>*/}
        {/*    <Grid className={"input_grid"} item xs={6} lg={6}>*/}
        {/*      <InputElement mask="999-99-99"*/}
        {/*                    variant="outlined"*/}
        {/*                    onChange={handleChange}*/}
        {/*                    value={formState.values.phone}*/}
        {/*                    name="phone"*/}
        {/*                    placeholder={t('common:enter_phone')}*/}
        {/*                    fullWidth*/}
        {/*                    error={hasError('phone')}*/}
        {/*      >*/}
        {/*        {(inputProps) => <TextField {...inputProps}*/}
        {/*                                    size="small"*/}
        {/*                                    // variant="outlined"*/}
        {/*                                    type="tel" /*disableUnderline*/}
        {/*                                    // onChange={props.onChange}*/}
        {/*                                    // error={hasError('phone')}*/}
        {/*         />*/}
        {/*       </InputElement>*/}
        {/*     </Grid>*/}
        {/*   </Grid>*/}
        {/* </Grid>*/}

        <Button
          variant="contained"
          color="secondary"
          type="submit"
          fullWidth
          disabled={formState.isSend || !formState.isValid}
          className={classes.button}>
          {t('common:CONFIRM')}
        </Button>

        {/*<Grid item className={classes.registered}>*/}
        {/*  <Link to="/sign-out">{t('common:I want to do it later')}</Link>*/}
        {/*</Grid>*/}
        {/*<BottomInfo />*/}
      </form>
    </LoginPageContainer>
  );
};

function mapState(state) {
  const {authentication} = state;
  // const {loggedIn} = state.authentication;
  return {authentication};
}

const actionCreators = {
  getMailConfirm: userActions.getMailConfirm,
  createCustomer: userActions.createCustomer,
  // logout: userActions.logout,
  // loginSocial: userActions.loginSocial,
};

class Wrapper extends React.Component {
  componentDidMount() {
    if (this.props.match.params.key) {
      this.props.getMailConfirm(this.props.match.params.key);
    }
  }

  /*
    componentDidUpdate(prevProps) {
    }
  */

  render() {
    return <ConfirmEmail {...this.props} />;
  }
}

Wrapper.propTypes = {
  history: PropTypes.object,
};

export default connect(mapState, actionCreators)(withRouter(Wrapper));
