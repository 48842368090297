import axInst from '../_helpers/axios-instance';
import {store} from '../_helpers';
import axInstAnonymous from '../_helpers/axios-instance-anonimus';
import moment from 'moment';
import axInstTest from '../_helpers/axios-instance-test';

export const userService = {
  login,
  logout,
  register,
  getFrontendData,
  getFullPaymentCalendar,
  getPaymentCalendarByOrganization,
  getPersonalManager,
  getManagerById,
  getCustomerData,
  getUserBalance,
  getContragentData,
  loginSocial,
  getMailConfirm,
  createCustomer,
  forgotPW,
  confirmPW,
  sendEmailConfirmation,
  getStatisticsOrder,
  getBonusBalance,
  getTurnoverStatistics,
  getPersonalManagers,
  getPromoCodes,
  getStatisticsOrderByOrganisation,
  getMostFrequentlyOrderedItems,
  getChartOrderPrices,
  getMoneyBackSummaryList,
  getIntegrationByType,
  getWarehouseman,
  getStatisticsOrderWarehouseman,
  getWarehouseUser,
  getWarehouseUserGoods,
  getOrderCreateInfo,
};

function login(username, password, captcha) {
  if (username.startsWith('+380')) {
    const phone = username.replace(/\s/g, '');
    return axInst
      .post('/rest-auth/login/', JSON.stringify({phone, password, captcha}))
      .then(handleResponse)
      .then(user => {
        // // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('user', JSON.stringify(user));
        return user;
      });
  } else {
    const email = username;
    return axInst
      .post('/rest-auth/login/', JSON.stringify({email, password, captcha}))
      .then(handleResponse)
      .then(user => {
        // // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('user', JSON.stringify(user));
        return user;
      });
  }
}

function loginSocial(access_token, url) {
  return axInst
    .post(url, JSON.stringify({access_token}))
    .then(handleResponse)
    .then(user => {
      return user;
    });
}

function getFrontendData() {
  return axInst
    .post('/api/get-frontend-data/', JSON.stringify({}))
    .then(handleResponse);
  // .then(data => {
  //   // // store user details and jwt token in local storage to keep user logged in between page refreshes
  //   // localStorage.setItem('user', JSON.stringify(user));
  //   return data;
  // });
}

function getFullPaymentCalendar(customerId) {
  return axInst
    .post('/api/get-full-payment-calendar/', {customer: customerId})
    .then(handleResponse)
    .then(respFinanceCalendar => {
      // add some calculations
      let data = Object.assign({}, respFinanceCalendar);

      data.payments = [];
      let outdatedDebt = 0;
      let recommendedToPay = 0;
      //let today = moment('2020-10-07');
      let today = moment();
      respFinanceCalendar.payments.map(item => {
        if (item.is_overdue) {
          outdatedDebt = item.full_payment; // last overdue record
        }
        if (item.date) {
          let [day, month, year] = item.date.split('.');
          let itemDate = moment(`${year}-${month}-${day}`);
          item.diffWithToday = itemDate.diff(today, 'days');
          if (item.diffWithToday >= 0 && item.diffWithToday <= 1) {
            item.recommend = true;
            recommendedToPay = item.full_payment;
          }
          if (item.is_overdue) {
            item.days_overdue = today.diff(itemDate, 'days');
          }
        }
        data.payments.push(item);
        return null;
      });
      data.outdatedDebt = outdatedDebt;
      data.recommendedToPay = recommendedToPay;
      return data;
    });
}

function getPaymentCalendarByOrganization(customerId) {
  return axInst
    .post('/api/get-payment_calendar_by_organization/', {customer: customerId})
    .then(handleResponse)
    .then(respFinanceCalendarByOrganization => {
      // add some calculations
      let data = Object.assign({}, respFinanceCalendarByOrganization);
      // let outdatedDebt = 0;
      let recommendedToPay = 0;
      let recommendedToPaySum = 0;
      //let today = moment('2020-10-07');
      let today = moment();

      data.result.organization_data.map(item => {
        // debugger
        item.payment_calendar.map(el => {
          if (el.is_overdue) {
            // outdatedDebt = el.full_payment; // last overdue record
          }
          if (el.date) {
            let [day, month, year] = el.date.split('.');
            let itemDate = moment(`${year}-${month}-${day}`);
            el.diffWithToday = itemDate.diff(today, 'days');
            if (el.diffWithToday >= 0 && el.diffWithToday <= 1) {
              el.recommend = true;
              recommendedToPay = el.full_payment;
            }
            if (el.is_overdue) {
              el.days_overdue = today.diff(itemDate, 'days');
            }
          }

          return null;
        });

        // if (item.is_overdue) {
        //   outdatedDebt = item.full_payment; // last overdue record
        // }
        // if (item.date) {
        //   let [day, month, year] = item.date.split('.');
        //   let itemDate = moment(`${year}-${month}-${day}`);
        //   item.diffWithToday = itemDate.diff(today, 'days');
        //   if (item.diffWithToday >= 0 && item.diffWithToday <= 1) {
        //     item.recommend = true;
        //     recommendedToPay = item.full_payment;
        //   }
        //   if(item.is_overdue){
        //     item.days_overdue = today.diff(itemDate,'days')
        //   }
        // }
        // data.payments.push(item);
        recommendedToPaySum += recommendedToPay;
        return null;
      });

      data.result.recommendedToPay = recommendedToPaySum;

      // data.payments = [];
      // let outdatedDebt = 0;
      // let recommendedToPay = 0;
      // //let today = moment('2020-10-07');
      // let today = moment();
      // respFinanceCalendarByOrganization.payments.map(item => {
      //   if (item.is_overdue) {
      //     outdatedDebt = item.full_payment; // last overdue record
      //   }
      //   if (item.date) {
      //     let [day, month, year] = item.date.split('.');
      //     let itemDate = moment(`${year}-${month}-${day}`);
      //     item.diffWithToday = itemDate.diff(today, 'days');
      //     if (item.diffWithToday >= 0 && item.diffWithToday <= 1) {
      //       item.recommend = true;
      //       recommendedToPay = item.full_payment;
      //     }
      //     if(item.is_overdue){
      //       item.days_overdue = today.diff(itemDate,'days')
      //     }
      //   }
      //   data.payments.push(item);
      //   return null;
      // });
      // data.outdatedDebt = outdatedDebt;
      // data.recommendedToPay = recommendedToPay;

      return data;
    });
}

function getPersonalManager(customerId) {
  return axInst
    .post('/api/get-personal-information-manager/', {customer_id: customerId})
    .then(handleResponse);
}

function getManagerById(managerId) {
  if (managerId) {
    return axInst
      .post('/api/get-manager/', {customer_user_id: managerId})
      .then(handleResponse);
  }
}

function getPersonalManagers(customerId) {
  return axInst
    .post('/api/get-personal-managers-by-customer/', {customer_id: customerId})
    .then(handleResponse);
}

function getPromoCodes(customerId) {
  return axInst
    .post('/api/get-promo-codes/', {customer_id: customerId})
    .then(handleResponse);
}

function getStatisticsOrder(customerId, dateFrom, dateUntil, manager) {
  return axInst
    .post('/api/get-statistics-order-by-product-group/', {
      customer_id: customerId,
      filter_by_date_order: [dateFrom, dateUntil],
      manager: manager,
    })
    .then(handleResponse);
}

function getStatisticsOrderByOrganisation(customerId, dateFrom, dateUntil) {
  return axInst
    .post('/api/get-statistics-orders/', {
      customer_id: customerId,
      filter_by_date_order: [dateFrom, dateUntil],
    })
    .then(handleResponse);
}

function getChartOrderPrices(customerId, dateFrom, dateUntil) {
  return axInst
    .post('/api/get-graphic-order-prices/', {
      customer_id: customerId,
      filter_by_date_order: [dateFrom, dateUntil],
    })
    .then(handleResponse);
}

function getMostFrequentlyOrderedItems(customerId, dateFrom, dateUntil) {
  return axInst
    .post('/api/get-most-frequently-ordered-items/', {
      customer_id: customerId,
      filter_by_date_order: [dateFrom, dateUntil],
    })
    .then(handleResponse);
}

function getBonusBalance(customerId, dateFrom, dateUntil) {
  return axInst
    .post('/api/get-bonus-statistics/', {
      customer_id: customerId,
      filter_by_date_order: [dateFrom, dateUntil],
    })
    .then(handleResponse);
}

function getTurnoverStatistics(customerId, dateFrom, dateUntil) {
  return axInst
    .post('/api/get-turnover-statistics/', {
      customer_id: customerId,
      filter_by_date_order: [dateFrom, dateUntil],
    })
    .then(handleResponse);
}

// dateFrom, dateUntil
function getStatisticsOrderWarehouseman(
  customerId,
  filter_start_date,
  filter_end_date,
) {
  return axInst
    .post('/api/get-statistics-orders-warehouseman', {
      customer_id: customerId,
      filter_start_date: filter_start_date,
      filter_end_date: filter_end_date,
    })
    .then(handleResponse);
}

function getWarehouseUser(customerId) {
  return axInst
    .post('/api/get-available-warehouses-for-warehouseman/', {
      customer_id: customerId,
    })
    .then(handleResponse);
}

function getWarehouseUserGoods(warehouseId, filter, perPage, page) {
  return axInst
    .post(
      '/api/get-items-warehouses-for-warehouseman/',
      {
        warehouse_id: warehouseId,
        per_page: perPage,
        filter: filter,
      },
      {
        params: {
          page: page,
        },
      },
    )
    .then(handleResponse);
}

function getMoneyBackSummaryList(customerId) {
  return axInst
    .post('/api/moneyback-summary-list/', {customer_id: customerId})
    .then(handleResponse);
}

function getWarehouseman(customerId, filterDate) {
  return axInst
    .post('/api/get-orders-warehouseman/', {
      customer_id: customerId,
      filter_date: filterDate,
    })
    .then(handleResponse);
}

function getIntegrationByType(type, customer_code) {
  return axInst
    .post('/api/get-integrations/type/', {
      type_integration: type,
      item_code_in_accounting_system: customer_code,
    })
    .then(handleResponse);
}

function getUserBalance(customerId) {
  const state = store.getState();
  const {authentication} = state;
  const {loggedIn} = authentication;

  const data = JSON.stringify({
    authenticated_user: loggedIn,
    // authenticated_user: true,
    customer_id: customerId,
  });

  return axInst.post('/api/get-user-balance/', data).then(handleResponse);
}

function getContragentData(customerId) {
  //const state = store.getState();
  //const {authentication} = state;
  const data = JSON.stringify({
    //authenticated_user: loggedIn,
    // authenticated_user: true,
    customer: customerId,
  });

  return axInst.post('/api/get-customer-data/', data).then(handleResponse);
}

function getCustomerData(customer) {
  return axInst
    .post('/rest-auth/login/', JSON.stringify({customer}))
    .then(handleResponse)
    .then(user => {
      // // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('user', JSON.stringify(user));
      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  // localStorage.removeItem('user');
}

function register(modernizedTel, email, password, captcha) {
  const username = email;
  const password1 = password;
  const password2 = password;
  const phone = modernizedTel;
  return axInstAnonymous
    .post(
      '/rest-auth/registration/',
      JSON.stringify({username, phone, password1, password2, email, captcha}),
    )
    .then(handleResponse);
  // .catch(error => {
  // })
  //     .then(user => {
  //       return user;
  //     });
}

function sendEmailConfirmation(email) {
  return axInst
    .post('/rest-auth/send-email-confirmation/', JSON.stringify({email}))
    .then(handleResponse);
}

function forgotPW(email, captcha) {
  return axInstAnonymous
    .post('/rest-auth/password/reset/', JSON.stringify({email, captcha}))
    .then(handleResponse);
}

function confirmPW(pw, match) {
  const uid = match.params.uid;
  const token = match.params.key;
  const new_password1 = pw;
  const new_password2 = pw;
  return axInstAnonymous
    .post(
      '/rest-auth/password/reset/confirm/',
      JSON.stringify({uid, token, new_password1, new_password2}),
    )
    .then(handleResponse);
}

function getMailConfirm(key) {
  return axInstAnonymous
    .post('/rest-auth/registration/verify-email/', JSON.stringify({key}))
    .then(handleResponse);
  // .then(user => {
  //   return user;
  // });
}

function createCustomer(customer) {
  const name = customer.name;
  const last_name = customer.last_name;
  // const phone =
  //   customer.operatorCode + ' ' + customer.phone.replace(/[^\d]/gi, '');
  const contractor_type = customer.contractor_type;
  let official_name = customer.official_name;
  let already_work = customer.already_work;
  let locality = customer.locality;
  let branch = customer.branch;
  if (contractor_type === 'PP') {
    official_name = '';
  }
  // if (contractor_type === "PP" || !already_work)
  if (!already_work) {
    locality = '';
    branch = '';
  }

  return axInst
    .post(
      '/rest-auth/create-customer/',
      JSON.stringify({
        name,
        last_name,
        // phone,
        contractor_type,
        official_name,
        already_work,
        locality,
        branch,
      }),
    )
    .then(handleResponse)
    .then(user => {
      return user;
    });
}

function handleResponse(response) {
  if (response.statusText === 'OK' || response.statusText === 'Created') {
    return response.data;
  }
  // const error = (data && data.message) || response.statusText;
  // return Promise.reject(error);
  //
  // debugger
  // return response.text().then(text => {
  //     const data = text && JSON.parse(text);
  //     debugger
  //     if (!response.ok) {
  //         if (response.status === 401) {
  //             // auto logout if 401 response returned from api
  //             logout();
  //             window.location.reload();
  //         }
  //
  //         const error = (data && data.message) || response.statusText;
  //         return Promise.reject(error);
  //     }
  //
  //     return data;
  // });
  debugger;
  return Promise.reject('Errrrr');
}

function getOrderCreateInfo(customerId, orderId) {
  return axInst
    .post('/api/get-order/', {order_id: orderId, customer_id: customerId})
    .then(handleResponse);
}
