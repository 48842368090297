import {makeStyles} from '@material-ui/core';
import React from 'react';

export const NumberInput = ({inputValue, setInputValue, maxCount}) => {
  const handleIncrement = () => {
    if (inputValue >= maxCount) return;
    setInputValue(inputValue + 1);
  };
  const handleDecrement = () => {
    if (inputValue <= 0) return;
    setInputValue(inputValue - 1);
  };

  const handleChange = e => {
    setInputValue(e.target.value);
  };

  return (
    <div className="product__count-control">
      <button
        type="button"
        onClick={handleDecrement}
        style={{pointerEvents: inputValue === 0 ? 'none' : 'auto'}}>
        <svg
          width="12"
          height="2"
          viewBox="0 0 12 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 1C0 0.585786 0.335786 0.25 0.75 0.25H11.25C11.6642 0.25 12 0.585786 12 1C12 1.41421 11.6642 1.75 11.25 1.75H0.75C0.335786 1.75 0 1.41421 0 1Z"
            fill="#1F1F1F"
            fillOpacity={inputValue === 0 ? '0.5' : '1'}
          />
        </svg>
      </button>

      <input
        value={inputValue}
        onChange={handleChange}
        type="number"
        name="number"
        min="0"
        max="100"
      />

      <button
        type="button"
        onClick={handleIncrement}
        style={{pointerEvents: inputValue === maxCount ? 'none' : 'auto'}}>
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z"
            fill="#1F1F1F"
            fillOpacity={inputValue === maxCount ? '0.5' : '1'}
          />
        </svg>
      </button>
    </div>
  );
};
