import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import Link from '@material-ui/core/Link';
import {useTranslation} from 'react-i18next';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles(theme => ({
  root: {},
  link: {
    color: '#606060',
    fontSize: 12,
  },
  separator: {
    color: '#606060',
    padding: '0 10px',
    fontSize: 12,
    '&:last-child': {
      display: 'none',
    },
  },
}));

const BreadcrumbsForSearch = props => {
  const classes = useStyles();
  const {catalogPage, searchStatus} = props;
  const {t} = useTranslation();
  if (searchStatus.catalog)
    return (
      <Breadcrumbs className={classes.root} separator={' - '}>

        {catalogPage.bread_crumb
          ? catalogPage.bread_crumb.map((item, key) => (
              <Fragment key={key}>
                {key === 0 ? (
                  <Link href={'/'} className={classes.link}>
                    {/* {item.bread_crumb_name} */}
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.215691 3.47961C0.215873 3.47943 0.216057 3.47925 0.21624 3.47906L3.47961 0.21582C3.61871 0.0766602 3.80364 0 4.00036 0C4.19707 0 4.38201 0.0765991 4.52117 0.215759L7.78283 3.47736C7.78393 3.47845 7.78503 3.47961 7.78612 3.48071C8.07177 3.76801 8.07128 4.23413 7.78472 4.52069C7.6538 4.65167 7.48089 4.72754 7.29601 4.73547C7.28851 4.73621 7.28094 4.73657 7.27331 4.73657H7.14324V7.13812C7.14324 7.61334 6.75658 8 6.28124 8H5.00451C4.87511 8 4.77013 7.89508 4.77013 7.76562V5.88281C4.77013 5.66595 4.59374 5.48956 4.37688 5.48956H3.62383C3.40697 5.48956 3.23058 5.66595 3.23058 5.88281V7.76562C3.23058 7.89508 3.12566 8 2.99621 8H1.71947C1.24413 8 0.857475 7.61334 0.857475 7.13812V4.73657H0.73687C0.540215 4.73657 0.355278 4.65997 0.216057 4.52081C-0.0708084 4.23376 -0.0709305 3.76685 0.215691 3.47961Z"
                        fill="#777777"
                      />
                    </svg>
                  </Link>
                ) : (
                  <Link
                    href={`/product-groups/${
                      item.bread_crumb_url.slice(-1) === '/'
                        ? item.bread_crumb_url.substring(
                            0,
                            item.bread_crumb_url.length - 1,
                          )
                        : item.bread_crumb_url
                    }`}
                    className={classes.link}>
                    {item.bread_crumb_name}
                  </Link>
                )}
              </Fragment>
            ))
          : null}
      </Breadcrumbs>
    );
  else if (searchStatus.promo)
    return (
      <Breadcrumbs className={classes.root} separator={' - '}>
        <Link href={`/`} className={classes.link}>
          {/* {t('common:Actions')} */}
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.215691 3.47961C0.215873 3.47943 0.216057 3.47925 0.21624 3.47906L3.47961 0.21582C3.61871 0.0766602 3.80364 0 4.00036 0C4.19707 0 4.38201 0.0765991 4.52117 0.215759L7.78283 3.47736C7.78393 3.47845 7.78503 3.47961 7.78612 3.48071C8.07177 3.76801 8.07128 4.23413 7.78472 4.52069C7.6538 4.65167 7.48089 4.72754 7.29601 4.73547C7.28851 4.73621 7.28094 4.73657 7.27331 4.73657H7.14324V7.13812C7.14324 7.61334 6.75658 8 6.28124 8H5.00451C4.87511 8 4.77013 7.89508 4.77013 7.76562V5.88281C4.77013 5.66595 4.59374 5.48956 4.37688 5.48956H3.62383C3.40697 5.48956 3.23058 5.66595 3.23058 5.88281V7.76562C3.23058 7.89508 3.12566 8 2.99621 8H1.71947C1.24413 8 0.857475 7.61334 0.857475 7.13812V4.73657H0.73687C0.540215 4.73657 0.355278 4.65997 0.216057 4.52081C-0.0708084 4.23376 -0.0709305 3.76685 0.215691 3.47961Z"
              fill="#777777"
            />
          </svg>
        </Link>
        <Link
          href={`/`}
          onClick={e => e.preventDefault()}
          className={classes.link}>
          {t('common:Search_parts')}
        </Link>
      </Breadcrumbs>
    );
  else
    return (
      <Breadcrumbs className={classes.root} separator={' - '}>
        <Link href={`/`} className={classes.link}>
          {/* {t('common:Spare parts')} */}
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.215691 3.47961C0.215873 3.47943 0.216057 3.47925 0.21624 3.47906L3.47961 0.21582C3.61871 0.0766602 3.80364 0 4.00036 0C4.19707 0 4.38201 0.0765991 4.52117 0.215759L7.78283 3.47736C7.78393 3.47845 7.78503 3.47961 7.78612 3.48071C8.07177 3.76801 8.07128 4.23413 7.78472 4.52069C7.6538 4.65167 7.48089 4.72754 7.29601 4.73547C7.28851 4.73621 7.28094 4.73657 7.27331 4.73657H7.14324V7.13812C7.14324 7.61334 6.75658 8 6.28124 8H5.00451C4.87511 8 4.77013 7.89508 4.77013 7.76562V5.88281C4.77013 5.66595 4.59374 5.48956 4.37688 5.48956H3.62383C3.40697 5.48956 3.23058 5.66595 3.23058 5.88281V7.76562C3.23058 7.89508 3.12566 8 2.99621 8H1.71947C1.24413 8 0.857475 7.61334 0.857475 7.13812V4.73657H0.73687C0.540215 4.73657 0.355278 4.65997 0.216057 4.52081C-0.0708084 4.23376 -0.0709305 3.76685 0.215691 3.47961Z"
              fill="#777777"
            />
          </svg>
        </Link>
        <Link
          href={`/`}
          onClick={e => e.preventDefault()}
          className={classes.link}>
          {t('common:Search_parts')}
        </Link>
      </Breadcrumbs>
    );
};

function mapState(state) {
  const {catalogPage, searchStatus} = state;
  return {
    catalogPage,
    searchStatus,
  };
}

export default connect(mapState)(BreadcrumbsForSearch);
